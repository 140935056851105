import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import * as l from 'lodash';
import { Grid } from '@material-ui/core';
import { getToken, getRoleId, navigate, parseGraphQLErrorMessage } from '../utils/utils';
import AuthenticatedPage from '../components/authenticated-page';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import { CustomDialog, ErrorDialog, RegulationTable, LoadingButton } from '../components/common';
import { RoleId, VaspRegulationStatus } from '../constants';
import { GET_VASP_REGULATION_DETAIL, UPDATE_VASP_REGULATION, DELIST_VASP } from '../crud';

export default function VaspRegulation({ location }) {
  const token = getToken();
  const roleId = getRoleId(token);
  if (
    (roleId !== RoleId.ACCOUNT_MANAGER && roleId !== RoleId.ADMIN) ||
    !location ||
    !location.state
  ) {
    navigate('/');
    return '';
  }

  const vaspCode = location.state ? location.state.parameter.vaspCode : '';
  const [vaspDetail, setVaspDetail] = useState({});
  const [updateSelectedIndex, setUpdateSelectedIndex] = useState(-1);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDone, setButtonDone] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  useQuery(GET_VASP_REGULATION_DETAIL, {
    variables: {
      vasp_code: vaspCode,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (l.has(data, 'getVaspRegulationDetail')) {
        const regulationData = data.getVaspRegulationDetail;
        setVaspDetail(regulationData);
      } else {
        setVaspDetail({});
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  const regulationStatus = l.get(vaspDetail, ['data', 'status'], 'none');

  const selectedIdentifier = l.get(vaspDetail, [
    'log',
    'data',
    'vaspResults',
    updateSelectedIndex,
    'vaspIdentifier',
  ]);

  const mutationToUse =
    regulationStatus === VaspRegulationStatus.PendingOnApprover
      ? DELIST_VASP
      : UPDATE_VASP_REGULATION;
  // if user is account manager, update will be standard update
  // if user is approver, update will be delist (approve or reject)
  const [updateVaspRegulation] = useMutation(mutationToUse, {
    variables: {
      vaspCode,
      vaspIdentifier: selectedIdentifier,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
  });

  const onClickApprove = () => {
    const vc = l.get(vaspDetail, ['data', 'vasp_code']);
    const afterStatus = l.get(vaspDetail, [
      'log',
      'data',
      'vaspResults',
      updateSelectedIndex,
      'regulatoryStatus',
    ]);
    setConfirmDialogMessage(`${vc} will become ${afterStatus} after action, continue?`);
    setConfirmDialogOpen(true);
    setSuccessDialogMessage('Approve Success');
  };

  const onClickReject = () => {
    const vc = l.get(vaspDetail, ['data', 'vasp_code']);
    // regulatoryStatus will be undefined if previous state was unknown
    const status = l.get(vaspDetail, ['data', 'data', 'regulatoryStatus'], 'unknown');
    setConfirmDialogMessage(`${vc} will remain ${status} after action, continue?`);
    setConfirmDialogOpen(true);
    setSuccessDialogMessage('Reject Success');
  };

  const handleConfirmClick = async () => {
    try {
      setButtonLoading(true);
      await updateVaspRegulation();
      setInformDialogOpen(true);
      setButtonDone(true);
    } catch (e) {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    } finally {
      setButtonLoading(false);
    }
  };

  const tableRows = l.get(vaspDetail, ['data', 'data'])
    ? [l.get(vaspDetail, ['data', 'data'])]
    : [];
  const updateRows = l.get(vaspDetail, ['log', 'data', 'vaspResults'], []);

  const showButtons =
    ((regulationStatus === VaspRegulationStatus.PendingOnAcc &&
      roleId === RoleId.ACCOUNT_MANAGER) ||
      (regulationStatus === VaspRegulationStatus.PendingOnApprover && roleId === RoleId.ADMIN)) &&
    updateRows.length > 0;

  const titleText = `${l.get(vaspDetail, ['data', 'vasp_name'], 'VASP Name')} (${vaspCode})`;

  const approveBtnActive = updateSelectedIndex !== -1;
  const rejectBtnActive = updateSelectedIndex === -1;

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'VASP Regulation', 'VASP Regulation Detail']}
          breadcrumbLinks={['/', '/vasp-regulation-list', '/vasp-regulation']}
        />
        <br />
        <h3>{titleText}</h3>
        <div>
          <RegulationTable
            loading={!l.has(vaspDetail, 'data')}
            data={tableRows}
            selectable={false}
          />
        </div>
        <h3>Pending Updates</h3>
        <div>
          <RegulationTable
            loading={!l.has(vaspDetail, 'data')}
            data={updateRows}
            selectable={showButtons}
            selectedIndex={updateSelectedIndex}
            setSelectedIndex={setUpdateSelectedIndex}
            idPrefix="pending-"
          />
        </div>
        {showButtons && (
          <Grid container style={{ justifyContent: 'flex-end' }}>
            <Grid item>
              <LoadingButton
                disabled={!approveBtnActive}
                loading={approveBtnActive && buttonLoading}
                done={approveBtnActive && buttonDone}
                onClick={onClickApprove}
                buttonText="Approve"
                id="approve_button"
              />
            </Grid>
            <Grid item>
              <LoadingButton
                disabled={!rejectBtnActive}
                loading={rejectBtnActive && buttonLoading}
                done={rejectBtnActive && buttonDone}
                onClick={onClickReject}
                buttonText="Reject"
                id="reject_button"
              />
            </Grid>
          </Grid>
        )}

        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
        <CustomDialog
          open={{ get: confirmDialogOpen, set: setConfirmDialogOpen }}
          title="Confirm"
          message={confirmDialogMessage}
          positiveButtonText="ok"
          onPositiveButtonClick={handleConfirmClick}
          negativeButtonText="cancel"
          onClose={() => {
            setConfirmDialogOpen(false);
          }}
          timeout={0}
        />
        <CustomDialog
          open={{ get: informDialogOpen, set: setInformDialogOpen }}
          title="Inform"
          message={successDialogMessage}
          positiveButtonText="ok"
          onClose={() => {
            setInformDialogOpen(false);
            navigate('/vasp-regulation-list');
          }}
          timeout={5000}
        />
      </AuthenticatedPage>
    </Layout>
  );
}

VaspRegulation.defaultProps = {
  location: undefined,
};

VaspRegulation.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        vaspCode: PropTypes.string,
      }),
    }),
  }),
};
